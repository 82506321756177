//
// map.js
// Theme module
//

const maps = document.querySelectorAll('[data-map]');
const accessToken = 'pk.eyJ1IjoicXVhbnR1bXN5cyIsImEiOiJja3R4ZXpta3kyc3FyMndwM2JqbGVhc2NtIn0.WH9NUCmWEKN-691Q9vaC5Q';

maps.forEach((map) => {
  const elementOptions = map.dataset.map ? JSON.parse(map.dataset.map) : {};

  const defaultOptions = {
    container: map,
    style: 'mapbox://styles/mapbox/light-v9',
    scrollZoom: false,
    interactive: false,
  };

  const options = {
    ...defaultOptions,
    ...elementOptions,
  };

  // Get access token
  mapboxgl.accessToken = accessToken;

  // Init map
  map = new mapboxgl.Map(options);

  new mapboxgl.Marker().setLngLat(elementOptions.center).addTo(map)
});
